import React from "react"
import PropTypes from "prop-types"

import "./layout.css"

const Layout = ({ children }) => {
  return (
    <div
      style={{
        margin: `0 auto`,
        // maxWidth: 960,
        // padding: `0px 1.0875rem 1.45rem`,
        paddingTop: 0,
        textAlign: "center",
        backgound: "#fffffe",
        // background:
        //   "linear-gradient(#FFDFB2 0%, #FEF8FA 100%) no-repeat 50% 50% / 100% 100%",
      }}
    >
      <main
        style={{
          borderLeft: "1px solid rgba(175, 134, 100, 0.23)",
          borderRight: "1px solid rgba(175, 134, 100, 0.23)",
          margin: "1.5em",
          marginTop: "0",
        }}
      >
        {children}
      </main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
